.react-pdf__Document {
    display: flex;
}

.react-pdf__Page {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.react-pdf__Page canvas {
    height: auto !important;
}

.react-pdf__message {
    padding: 20px;
    color: white;
}

.react-pdf__Page.prevPage {
    position: absolute !important;
    z-index: 1;
}

.highlight-box {
    position: absolute;
    min-width: 0;
    transition: background-color 0.25s;
    padding: 0;
    border:none;
    border:solid 1px transparent;
}

.highlight-box span{
    font-family: 'Poppins';
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 8px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.highlight-box span div{
    overflow: hidden;
    text-overflow: ellipsis;
    padding-inline: 0.4em;
}

.highlight-box span.hide_text{
    opacity: 0;
}

.highlight-box span.show_label{
    opacity: 0.3;
}

.highlight-box span:hover{
    opacity: 1;
    transition: opacity 0.3s;
}

.highlight-box.text span:hover{
    opacity: 0;
}


.highlight-box.z-below{
    z-index: 0;
}

.highlight-box.z-above{
    z-index: 2;
}

.highlight-box.completed span{
    opacity: 1;
}

.highlight-box.selected{
    opacity: 0;
}

.highlight-box.type_Btn span{
    opacity: 0;
}


button.highlight-selected{
    position: absolute;
    padding: 0;
    min-width: 0;
    border-radius: 0;
}

button.highlight-selected span{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 8px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
}

button.highlight-selected span div{
    overflow: hidden;
    text-overflow: ellipsis;
    padding-inline: 0.4em;
}