.custom-handle {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #1153aa;
    opacity: 0.75;
    border-radius: 4px;
  }
  .custom-handle-sw {
    bottom: -4px;
    left: -4px;
    cursor: sw-resize;
  }
  .custom-handle-se {
    bottom: -4px;
    right: -4px;
    cursor: se-resize;
  }
  .custom-handle-nw {
    top: -4px;
    left: -4px;
    cursor: nw-resize;
  }
  .custom-handle-ne {
    top: -4px;
    right: -4px;
    cursor: ne-resize;
  }
  .custom-handle-w,
  .custom-handle-e {
    top: 50%;
    margin-top: -4px;
    cursor: ew-resize;
  }
  .custom-handle-w {
    left: -4px;
  }
  .custom-handle-e {
    right: -4px;
  }
  .custom-handle-n,
  .custom-handle-s {
    left: 50%;
    margin-left: -4px;
    cursor: ns-resize;
  }
  .custom-handle-n {
    top: -4px;
  }
  .custom-handle-s {
    bottom: -4px;
  }
  .custom-resize-handle-component {
    background-color: red;
  }


  .react-resizable-handle {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #1153aa;
    opacity: 0.75;
    border-radius: 4px;
  }


  .react-resizable-handle-sw {
    bottom: -4px;
    left: -4px;
    cursor: sw-resize;
  }
  .react-resizable-handle-se {
    bottom: -4px;
    right: 5px;
    cursor: se-resize;
  }
  .react-resizable-handle-nw {
    top: -4px;
    left: -4px;
    cursor: nw-resize;
  }
  .react-resizable-handle-ne {
    top: -4px;
    right: -4px;
    cursor: ne-resize;
  }
  .react-resizable-handle-w,
  .react-resizable-handle-e {
    top: 50%;
    margin-top: -4px;
    cursor: ew-resize;
  }
  .react-resizable-handle-w {
    left: -4px;
  }
  .react-resizable-handle-e {
    right: -4px;
  }
  .react-resizable-handle-n,
  .react-resizable-handle-s {
    left: 50%;
    margin-left: -4px;
    cursor: ns-resize;
  }
  .react-resizable-handle-n {
    top: -4px;
  }
  .react-resizable-handle-s {
    bottom: -4px;
  }

  .section .section__up, .section .section__down, .section .row-item__up, .section .row-item__down,
  .section .field-item__up, .section .field-item__down, .section .field-item__left, .section .field-item__right,
  .section .subsection-item__up, .section .subsection-item__down, .row-item .row-item__number, .section .section__add-row, 
  .subsection-item .subsection-item__add-row, .section .field-item__title-info, .group-item__title-info, .group-item__multi-title-info, .box-item__multi-info{
    opacity: 0;
    transition: opacity 0.3s
  }

  .section:hover .section__up, .section:hover .section__down, .row-item:hover .row-item__up, .row-item:hover .row-item__down,
  .field-item:hover .field-item__up, .field-item:hover .field-item__down, .field-item:hover .field-item__left, .field-item:hover .field-item__right,
  .subsection-item:hover .subsection-item__up, .subsection-item:hover .subsection-item__down, .row-item:hover .row-item__number, .section:hover .section__add-row,
  .subsection-item:hover .subsection-item__add-row, .field-item__title:hover .field-item__title-info, .group-item__title:hover .group-item__title-info, 
  .group-item__multi:hover .group-item__multi-title-info, .box-item__multi:hover .box-item__multi-info{
    opacity: 1;
  }

  .field-item .field-item__title, .section .section__title, .subsection-item .subsection-item__title, .group-item__title{
    border: solid 1px rgba(0,0,0,0);
    transition: border 0.3s;
    margin-left: -0.25rem;
    padding-inline: 0.25rem;
    cursor: text;
  }

  .field-item__title:hover, .section .section__title:hover, .subsection-item .subsection-item__title:hover, .group-item__title:hover{
    border-color: rgba(0,0,0,0.38);
  }

  .row-item, .subsection-item{
    /* border-color: rgba(0,0,0,0.14); */
    transition: border 0.3s;
  }
  
  .row-item:hover, .subsection-item:hover{
    border-color: rgba(0,0,0,0.38);
  }